let l = (x, y) => ({ x, y })
export const NPCs = {
  "Large Sphere Traders": [
    l(103, 56),
    l(205, 16),
    l(256, 75),
    l(169, 239),
    l(220, 302),
    l(247, 242)
  ],
  "Small Sphere Traders": [
    l(347, 20),
    l(95, 136),
    l(188, 109),
    l(187, 151),
    l(236, 208),
    l(268, 200),
    l(329, 182),
    l(77, 298)
  ],
  "Treasure Traders": [
    l(22, 27),
    l(389, 40),
    l(49, 212),
    l(403, 287)
  ],
  "Rest NPCs": [
    l(220, 67),
    l(281, 116),
    l(160, 199),
    l(238, 169),
    l(265, 119),
    l(143, 202),
    l(205, 251)
  ]
}
