<template>
  <div class="hello">
    <h1>BDSP Grand Underground Map</h1>
    <svg width="430" height="320" style="background-color: black">
      <!--g><image href="../assets/underground.png" width="640" height="360" /></g-->
      <line
        v-for="(line, idx) in lines"
        :key="`line-${idx}`"
        :x1="line.x1"
        :x2="line.x2"
        :y1="line.y1"
        :y2="line.y2"
        :stroke="line.stroke"
        stroke-width="2"
        stroke-linecap="round"
      />
      <image
        v-for="(location, idx) in locations"
        :key="`${location.id}-${idx}`"
        :href="icons[location.cavern]"
        :x="location.x"
        :y="location.y"
        :width="location.size"
        :height="location.size"
      />
      <g
        v-for="(region, idx) in regions"
        :key="`region-${idx}`"
      >
        <polygon
          :points="`${region.points}`"
          :style="regionStyle(region)"
          :onclick="select(region)"
        />
      </g>
    </svg>
    
    <div v-if="selectedRegion" style="max-width: 400px; margin: auto;">
      <o-table
        :data="selectedRegion.digLocations"
        :bordered="true"
      >
        <o-table-column field="cave" label="Dig location" width="40" v-slot="props">
          {{ props.row }}
        </o-table-column>
      </o-table>
    </div>
    <div class="tagline">
      Any issues or bugs, please let me know on <a href="http://twitter.com/Dezzles" target="_blank">Twitter @Dezzles</a>
    </div>
  </div>
</template>

<script>
import { Paths, Caverns, Regions } from './map'
import { LocationMap } from './LocationMap'
import {Icons} from './icons';
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      selectedRegion: null,
      icons: Icons,
      lines: Paths,
      locations: Caverns,
      regions: Regions,
      locationMap: LocationMap
    };
  },
  methods: {
    select: function (val) {
      return () => (this.selectedRegion = val);
    },
    regionStyle: function(region) {
      return {
        fill: `${region.fillColour}33`,
        'fill-rule': 'evenodd',
        'stroke': (region === this.selectedRegion) ? region.fillColour : '',
        'stroke-width': 2
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.table {
  background-color: transparent !important;
}

.b-table.table-wrapper.has-mobile-cards tr:not([class*=is-]) {
    background-color: transparent !important;
}

.tagline {
  margin-top: 20px;
  font-size: .8em;
}
.multiselect {
  width: 300px !important;
}
.multiselect-single-label {
  color: gray !important;
}

h1 { 
  color: #bac6d3;
}
</style>
