export const LocationMap = {
  'spaciouscave': 'Spacious Cave',
  'grasslandcave': 'Grassland Cave',
  'fountainspringcave': 'Fountainspring Cave',
  'rockycave': 'Rocky Cave',
  'volcaniccave': 'Volcanic Cave',
  'swampycave': 'Swampy Cave',
  'dazzlingcave': 'Dazzling Cave',
  'whiteoutcave': 'Whiteout Cave',
  'icycave': 'Icy Cave',
  'riverbankcave': 'Riverbank Cave',
  'sandsearcave': 'Sandsear Cave',
  'stillwatercavern': 'Still-Water Cavern',
  'sunlitcavern': 'Sunlit Cavern',
  'bigbluffcavern': 'Big Bluff Cavern',
  'stargleamcavern': 'Stargleam Cavern',
  'glacialcavern': 'Glacial Cavern',
  'bogsunkcavern': 'Bogsunk Cavern',
  'typhlocavern': 'Typhlo Cavern',
}