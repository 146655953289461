<template>
  <div class="hello">
    <h1>BDSP Grand Underground Map</h1>
    <o-field label="Game">
      <Multiselect
        v-model="game"
        :options="['Brilliant Diamond', 'Shining Pearl']"
        :canClear="false"
      />
    </o-field>
    <o-field label="Pokemon Search">
      <Multiselect
        v-model="selectedPokemon"
        :options="pokemonOptions"
        :canClear="false"
        :searchable="true"
      />
    </o-field>

    <svg width="430" height="320" style="background-color: black">
      <!--g><image href="../assets/underground.png" width="640" height="360" /></g-->
      <line
        v-for="(line, idx) in lines"
        :key="`line-${idx}`"
        :x1="line.x1"
        :x2="line.x2"
        :y1="line.y1"
        :y2="line.y2"
        :stroke="line.stroke"
        stroke-width="2"
        stroke-linecap="round"
      />
      <image
        v-for="(location, idx) in locations"
        :key="`${location.id}-${idx}`"
        :href="icons[location.cavern]"
        :x="location.x"
        :y="location.y"
        :width="location.size"
        :height="location.size"
        :onclick="select(location)"
        :style="getStyle(location)"
      />
    </svg>
    <div v-if="currentGame.length === 0">
      You can't find {{ selectedPokemon }} in {{ game }} in the Grand Underground
    </div>
    <div v-if="currentGame.length !== 0" style="max-width: 400px; margin: auto;">
      Where to find {{ selectedPokemon }} in {{ game }}
      <o-table
        :data="currentGame"
        :bordered="true"
        
      >
        <o-table-column field="cave" label="Hideaway" width="40" v-slot="props">
          {{ props.row.cave }}
        </o-table-column>
        <o-table-column field="requirement" label="Requirement" width="40" v-slot="props">
          {{ props.row.requirement }}
        </o-table-column>
      </o-table>
    </div>
    <div class="tagline">
      Any issues or bugs, please let me know on <a href="http://twitter.com/Dezzles" target="_blank">Twitter @Dezzles</a>
    </div>
  </div>
</template>

<script>
const pokemon = require('./pokemon.json')
import '@vueform/multiselect/themes/default.css' // This is required, we use the same css as selectize.js
import Multiselect from '@vueform/multiselect'
import { Paths, Caverns } from './map'
import {Icons} from './icons';
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    Multiselect
  },
  data() {
    return {
      test: '',
      pokemon,
      game: 'Brilliant Diamond',
      selectedPokemon: 'Charmander',
      selected: null,
      icons: Icons,
      lines: Paths,
      locations: Caverns,
      locationMap: {
        'spaciouscave': 'Spacious Cave',
        'grasslandcave': 'Grassland Cave',
        'fountainspringcave': 'Fountainspring Cave',
        'rockycave': 'Rocky Cave',
        'volcaniccave': 'Volcanic Cave',
        'swampycave': 'Swampy Cave',
        'dazzlingcave': 'Dazzling Cave',
        'whiteoutcave': 'Whiteout Cave',
        'icycave': 'Icy Cave',
        'riverbankcave': 'Riverbank Cave',
        'sandsearcave': 'Sandsear Cave',
        'stillwatercavern': 'Still-Water Cavern',
        'sunlitcavern': 'Sunlit Cavern',
        'bigbluffcavern': 'Big Bluff Cavern',
        'stargleamcavern': 'Stargleam Cavern',
        'glacialcavern': 'Glacial Cavern',
        'bogsunkcavern': 'Bogsunk Cavern',
        'typhlocavern': 'Typhlo Cavern',
      }
    };
  },
  methods: {
    select: function (val) {
      return () => (this.selected = val);
    },
    getStyle(location) {
      if (this.selectedPokemon == null) {
        return {
          filter: 'brightness(0.2)'
        }
      }
      let locs = this.pokemon[this.selectedPokemon][this.game]
      let actual = this.locationMap[location.cavern]
      const count = locs.filter(v => v.cave === actual).length
      if (count > 0) {
        return {
          filter: 'brightness(1)',
          outline: '1px solid yellow'
        };
      } else {
        return {
          filter: 'brightness(0.2)'
        };
      }
    },
  },
  computed: {
    pokemonOptions: function () {
      return Object.keys(this.pokemon).sort()
        //.filter(v => this.pokemon[v][this.game].length)
    },
    currentPokemon: function () {
      return this.pokemon[this.selectedPokemon]
    },
    currentGame: function () {
      console.log(this.currentPokemon)
      return this.currentPokemon[this.game]
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.table {
  background-color: transparent !important;
}

.b-table.table-wrapper.has-mobile-cards tr:not([class*=is-]) {
    background-color: transparent !important;
}

.tagline {
  margin-top: 20px;
  font-size: .8em;
}
.multiselect {
  width: 300px !important;
}
.multiselect-single-label {
  color: gray !important;
}

h1 { 
  color: #bac6d3;
}
</style>
