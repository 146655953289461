const SIZE_M = 12;
const SIZE_L = 24;
const REGION_1 = "pink";
const REGION_2 = "green";
const REGION_3 = "blue";
const REGION_4 = "#FFA500";
const REGION_5 = "red";
const REGION_6 = "purple";
const loc = (id, cavern, x, y, size) => ({ id, cavern, x, y, size });
const line = (x1, y1, x2, y2, stroke) => ({ x1, y1, x2, y2, stroke });

export const Caverns = [
  loc("icy_cave_0", "icycave", 30, 8, SIZE_M),
  loc("icy_cave_1", "icycave", 46, 51, SIZE_M),
  loc("icy_cave_2", "icycave", 114, 3, SIZE_M),
  loc("icy_cave_3", "icycave", 181, 130, SIZE_M),
  loc("spacious_cave_0", "spaciouscave", 97, 27, SIZE_M),
  loc("spacious_cave_1", "spaciouscave", 107, 51, SIZE_M),
  loc("spacious_cave_2", "spaciouscave", 351, 75, SIZE_M),
  loc("spacious_cave_3", "spaciouscave", 72, 94, SIZE_M),
  loc("spacious_cave_4", "spaciouscave", 42, 135, SIZE_M),
  loc("spacious_cave_5", "spaciouscave", 120, 123, SIZE_M),
  loc("spacious_cave_6", "spaciouscave", 120, 150, SIZE_M),
  loc("spacious_cave_7", "spaciouscave", 231, 114, SIZE_M),
  loc("spacious_cave_8", "spaciouscave", 294, 157, SIZE_M),
  loc("spacious_cave_9", "spaciouscave", 372, 172, SIZE_M),
  loc("spacious_cave_10", "spaciouscave", 342, 172, SIZE_M),
  loc("spacious_cave_11", "spaciouscave", 294, 184, SIZE_M),
  loc("spacious_cave_12", "spaciouscave", 367, 256, SIZE_M),
  loc("spacious_cave_13", "spaciouscave", 304, 256, SIZE_M),
  loc("spacious_cave_14", "spaciouscave", 220, 246, SIZE_M),
  loc("spacious_cave_15", "spaciouscave", 183, 193, SIZE_M),
  loc("spacious_cave_16", "spaciouscave", 112, 186, SIZE_M),
  loc("spacious_cave_17", "spaciouscave", 138, 219, SIZE_M),
  loc("spacious_cave_18", "spaciouscave", 138, 258, SIZE_M),
  loc("spacious_cave_19", "spaciouscave", 24, 235, SIZE_M),
  loc("whiteout_cave_0", "whiteoutcave", 215, 11, SIZE_M),
  loc("whiteout_cave_1", "whiteoutcave", 200, 23, SIZE_M),
  loc("whiteout_cave_2", "whiteoutcave", 194, 62, SIZE_M),
  loc("whiteout_cave_3", "whiteoutcave", 232, 65, SIZE_M),
  loc("fountainspring_0", "fountainspringcave", 150, 82, SIZE_M),
  loc("fountainspring_1", "fountainspringcave", 191, 87, SIZE_M),
  loc("fountainspring_2", "fountainspringcave", 153, 136, SIZE_M),
  loc("fountainspring_3", "fountainspringcave", 63, 232, SIZE_M),
  loc("fountainspring_4", "fountainspringcave", 199, 297, SIZE_M),
  loc("fountainspring_5", "fountainspringcave", 317, 281, SIZE_M),
  loc("fountainspring_6", "fountainspringcave", 301, 121, SIZE_M),
  loc("grassland_cave_0", "grasslandcave", 57, 135, SIZE_M),
  loc("grassland_cave_1", "grasslandcave", 97, 118, SIZE_M),
  loc("grassland_cave_2", "grasslandcave", 79, 207, SIZE_M),
  loc("grassland_cave_3", "grasslandcave", 61, 274, SIZE_M),
  loc("grassland_cave_4", "sunlitcavern", 132, 232, SIZE_L),
  loc("grassland_cave_5", "grasslandcave", 334, 100, SIZE_M),
  loc("stillwater_cavern_0", "stillwatercavern", 6, 150, SIZE_L),
  loc("riverbankcave_0", "riverbankcave", 72, 135, SIZE_M),
  loc("riverbankcave_1", "riverbankcave", 160, 268, SIZE_M),
  loc("riverbankcave_2", "riverbankcave", 43, 250, SIZE_M),
  loc("dazzling_cave_0", "dazzlingcave", 46, 94, SIZE_M),
  loc("dazzling_cave_1", "stargleamcavern", 201, 149, SIZE_L),
  loc("dazzling_cave_2", "dazzlingcave", 232, 178, SIZE_M),
  loc("dazzling_cave_3", "dazzlingcave", 316, 33, SIZE_M),
  loc("dazzling_cave_4", "dazzlingcave", 334, 234, SIZE_M),
  loc("swampy_cave_0", "swampycave", 299, 304, SIZE_M),
  loc("swampy_cave_1", "bogsunkcavern", 259, 274, SIZE_L),
  loc("swampy_cave_2", "swampycave", 213, 284, SIZE_M),
  loc("swampy_cave_3", "swampycave", 182, 243, SIZE_M),
  loc("swampy_cave_4", "swampycave", 97, 274, SIZE_M),
  loc("swampy_cave_5", "swampycave", 79, 261, SIZE_M),
  loc("swampy_cave_6", "swampycave", 334, 46, SIZE_M),
  loc("glacial_cavern_0", "glacialcavern", 142, 21, SIZE_L),
  loc("volcanic_cave_0", "volcaniccave", 252, 38, SIZE_M),
  loc("volcanic_cave_1", "volcaniccave", 275, 48, SIZE_M),
  loc("volcanic_cave_2", "volcaniccave", 352, 33, SIZE_M),
  loc("volcanic_cave_3", "volcaniccave", 261, 171, SIZE_M),
  loc("volcanic_cave_4", "volcaniccave", 316, 189, SIZE_M),
  loc("swampy_cave_7", "swampycave", 78, 72, SIZE_M),
  loc("typhlo_cavern_0", "typhlocavern", 270, 63, SIZE_L),
  loc("sandsear_cave_1", "sandsearcave", 370, 57, SIZE_M),
  loc("sandsear_cave_2", "sandsearcave", 341, 212, SIZE_M),
  loc("sandsear_cave_3", "sandsearcave", 367, 212, SIZE_M),
  loc("rocky_cave_0", "rockycave", 384, 300, SIZE_M),
  loc("rocky_cave_1", "rockycave", 264, 225, SIZE_M),
  loc("rocky_cave_2", "rockycave", 224, 221, SIZE_M),
  loc("rocky_cave_3", "rockycave", 357, 172, SIZE_M),
  loc("rocky_cave_4", "rockycave", 390, 72, SIZE_M),
  loc("rocky_cave_5", "rockycave", 276, 88, SIZE_M),
  loc("big_bluff_cavern_0", "bigbluffcavern", 396, 145, SIZE_L),
]

export const Paths = [
  line(22, 23, 22, 31, REGION_1),
  line(22, 23, 35, 23, REGION_1),
  line(35, 18, 35, 31, REGION_1),
  line(22, 31, 65, 31, REGION_1),
  line(40, 13, 47, 13, REGION_1),
  line(47, 13, 47, 38, REGION_1),
  line(47, 22, 61, 22, REGION_1),
  line(61, 22, 61, 31, REGION_1),
  line(65, 31, 65, 35, REGION_1),
  line(65, 35, 73, 35, REGION_1),
  line(73, 29, 73, 38, REGION_1),
  line(73, 29, 89, 29, REGION_1),
  line(89, 29, 89, 38, REGION_1),
  line(73, 38, 89, 38, REGION_1),
  line(89, 32, 113, 32, REGION_1),
  line(103, 32, 103, 56, REGION_1),
  line(103, 56, 112, 56, REGION_1),
  line(103, 46, 131, 46, REGION_1),
  line(131, 46, 131, 53, REGION_1),
  line(131, 53, 154, 53, REGION_1),
  line(154, 38, 154, 53, REGION_1),
  line(154, 49, 163, 49, REGION_1),
  line(163, 49, 163, 58, REGION_1),
  line(163, 58, 170, 58, REGION_1),
  line(170, 58, 170, 67, REGION_1),
  line(170, 67, 220, 67, REGION_1),
  line(47, 38, 56, 38, REGION_1),
  line(56, 38, 56, 44, REGION_1),
  line(56, 44, 62, 44, REGION_1),
  line(62, 44, 62, 56, REGION_1),
  line(22, 56, 62, 56, REGION_1),
  line(62, 50, 73, 50, REGION_1),
  line(73, 46, 73, 50, REGION_1),
  line(73, 46, 83, 46, REGION_1),
  line(83, 38, 83, 46, REGION_1),
  line(22, 56, 22, 64, REGION_1),
  line(22, 64, 31, 64, REGION_1),
  line(31, 49, 31, 64, REGION_1),
  line(31, 49, 40, 49, REGION_1),
  line(40, 49, 40, 67, REGION_1),
  line(40, 67, 52, 67, REGION_1),
  line(52, 58, 52, 74, REGION_1),
  line(37, 74, 67, 74, REGION_1),
  line(37, 74, 37, 83, REGION_1),
  line(37, 83, 46, 83, REGION_1),
  line(46, 83, 46, 91, REGION_1),
  line(41, 91, 46, 91, REGION_1),
  line(41, 91, 41, 100, REGION_1),
  line(41, 100, 62, 100, REGION_1),
  line(67, 74, 67, 83, REGION_1),
  line(58, 83, 67, 83, REGION_1),
  line(58, 83, 58, 91, REGION_1),
  line(58, 91, 62, 91, REGION_1),
  line(62, 91, 62, 100, REGION_1),
  line(145, 53, 145, 62, REGION_1),
  line(139, 62, 145, 62, REGION_1),
  line(139, 62, 139, 76, REGION_1),
  line(139, 76, 178, 76, REGION_1),
  line(178, 67, 178, 76, REGION_1),
  line(113, 26, 113, 32, REGION_1),
  line(113, 26, 118, 26, REGION_1),
  line(118, 19, 118, 26, REGION_1),
  line(109, 19, 118, 19, REGION_1),
  line(109, 8, 109, 19, REGION_1),
  line(109, 8, 133, 8, REGION_1),
  line(133, 8, 133, 19, REGION_1),
  line(128, 19, 133, 19, REGION_1),
  line(128, 19, 128, 23, REGION_1),
  line(118, 23, 128, 23, REGION_1),
  line(133, 14, 155, 14, REGION_1),
  line(140, 5, 140, 14, REGION_1),
  line(140, 5, 155, 5, REGION_1),
  line(155, 5, 155, 14, REGION_1),
  line(155, 8, 163, 8, REGION_1),
  line(163, 5, 178, 5, REGION_1),
  line(163, 5, 163, 14, REGION_1),
  line(163, 14, 178, 14, REGION_1),
  line(178, 5, 178, 23, REGION_1),
  line(178, 23, 191, 23, REGION_1),
  line(191, 20, 191, 23, REGION_1),
  line(191, 20, 196, 20, REGION_1),
  line(196, 16, 196, 20, REGION_1),
  line(196, 16, 220, 16, REGION_1),
  line(205, 16, 205, 41, REGION_1),
  line(185, 23, 185, 34, REGION_1),
  line(185, 34, 190, 34, REGION_1),
  line(190, 34, 190, 38, REGION_1),
  line(190, 38, 194, 38, REGION_1),
  line(194, 38, 194, 41, REGION_1),
  line(194, 41, 205, 41, REGION_1),
  line(220, 15, 220, 67, REGION_1),
  line(220, 28, 230, 28, REGION_1),
  line(230, 28, 230, 35, REGION_1),
  line(220, 35, 230, 35, REGION_1),
  line(220, 43, 233, 43, REGION_1),
  line(233, 43, 233, 50, REGION_1),
  line(220, 50, 233, 50, REGION_1),
  line(220, 59, 236, 59, REGION_1),
  line(236, 59, 236, 70, REGION_1),

  line(259, 41, 259, 34, REGION_2),
  line(259, 34, 272, 34, REGION_2),
  line(272, 34, 272, 41, REGION_2),
  line(272, 41, 286, 41, REGION_2),
  line(286, 41, 286, 28, REGION_2),
  line(286, 28, 305, 28, REGION_2),
  line(305, 28, 305, 20, REGION_2),
  line(286, 37, 296, 37, REGION_2),
  line(296, 37, 296, 20, REGION_2),
  line(296, 20, 314, 20, REGION_2),
  line(314, 20, 314, 26, REGION_2),
  line(314, 26, 322, 26, REGION_2),
  line(322, 26, 322, 38, REGION_2),
  line(281, 41, 281, 53, REGION_2),
  line(256, 55, 307, 55, REGION_2),
  line(307, 55, 307, 94, REGION_2),
  line(307, 94, 256, 94, REGION_2),
  line(256, 55, 256, 94, REGION_2),
  line(256, 79, 307, 79, REGION_2),
  line(256, 70, 307, 70, REGION_2),
  line(256, 62, 263, 62, REGION_2),
  line(263, 62, 263, 70, REGION_2),
  line(307, 62, 299, 62, REGION_2),
  line(299, 62, 299, 70, REGION_2),
  line(263, 79, 263, 86, REGION_2),
  line(263, 86, 256, 86, REGION_2),
  line(299, 79, 299, 86, REGION_2),
  line(299, 86, 307, 86, REGION_2),
  line(281, 96, 281, 116, REGION_2),
  line(281, 116, 340, 116, REGION_2),
  line(340, 116, 340, 88, REGION_2),
  line(340, 88, 332, 88, REGION_2),
  line(332, 88, 332, 74, REGION_2),
  line(332, 74, 340, 74, REGION_2),
  line(340, 74, 340, 68, REGION_2),
  line(340, 68, 332, 68, REGION_2),
  line(332, 68, 332, 62, REGION_2),
  line(332, 62, 340, 62, REGION_2),
  line(340, 62, 340, 53, REGION_2),

  line(281, 109, 307, 109, REGION_2),
  line(307, 109, 307, 126, REGION_2),
  line(290, 109, 290, 116, REGION_2),
  line(295, 116, 295, 127, REGION_2),
  line(295, 127, 322, 127, REGION_2),
  line(322, 127, 322, 116, REGION_2),
  line(337, 106, 328, 106, REGION_2),
  line(328, 106, 328, 95, REGION_2),
  line(328, 95, 340, 95, REGION_2),
  line(344, 106, 376, 106, REGION_2),
  line(376, 106, 376, 91, REGION_2),
  line(376, 91, 382, 91, REGION_2),
  line(382, 91, 382, 88, REGION_2),
  line(382, 88, 395, 88, REGION_2),
  line(359, 106, 359, 113, REGION_2),
  line(359, 113, 368, 113, REGION_2),
  line(368, 113, 368, 106, REGION_2),
  line(376, 98, 395, 98, REGION_2),
  line(395, 98, 395, 67, REGION_2),
  line(389, 98, 389, 106, REGION_2),
  line(389, 106, 406, 106, REGION_2),
  line(406, 106, 406, 95, REGION_2),
  line(406, 95, 395, 95, REGION_2),
  line(395, 95, 396, 77, REGION_2),
  line(396, 77, 409, 77, REGION_2),
  line(409, 77, 409, 67, REGION_2),
  line(409, 67, 388, 67, REGION_2),
  line(388, 67, 388, 56, REGION_2),
  line(388, 56, 403, 56, REGION_2),
  line(403, 56, 403, 67, REGION_2),
  line(388, 62, 378, 62, REGION_2),
  line(395, 56, 395, 49, REGION_2),
  line(395, 49, 374, 49, REGION_2),
  line(374, 49, 374, 25, REGION_2),
  line(374, 25, 361, 25, REGION_2),
  line(374, 31, 389, 31, REGION_2),
  line(389, 31, 389, 49, REGION_2),
  line(374, 40, 389, 40, REGION_2),
  line(361, 28, 361, 20, REGION_2),
  line(361, 20, 347, 20, REGION_2),
  line(347, 20, 347, 28, REGION_2),
  line(347, 28, 361, 28, REGION_2),
  line(358, 28, 358, 38, REGION_2),
  line(332, 80, 355, 80, REGION_2),

  line(196, 151, 187, 151, REGION_3),
  line(187, 151, 187, 136, REGION_3),
  line(187, 136, 205, 136, REGION_3),
  line(205, 136, 205, 143, REGION_3),
  line(229, 143, 196, 143, REGION_3),
  line(196, 143, 196, 164, REGION_3),
  line(196, 164, 191, 164, REGION_3),
  line(191, 164, 191, 173, REGION_3),
  line(196, 155, 205, 155, REGION_3),
  line(187, 173, 196, 173, REGION_3),
  line(196, 173, 196, 182, REGION_3),
  line(196, 182, 187, 182, REGION_3),
  line(187, 182, 187, 173, REGION_3),
  line(196, 176, 229, 176, REGION_3),
  line(229, 176, 229, 155, REGION_3),
  line(229, 155, 233, 155, REGION_3),
  line(233, 155, 233, 146, REGION_3),
  line(233, 146, 229, 146, REGION_3),
  line(229, 146, 229, 137, REGION_3),
  line(229, 137, 238, 137, REGION_3),
  line(238, 137, 238, 146, REGION_3),
  line(238, 146, 233, 146, REGION_3),
  line(229, 169, 238, 169, REGION_3),
  line(238, 169, 238, 184, REGION_3),
  line(238, 184, 220, 184, REGION_3),
  line(220, 184, 220, 176, REGION_3),
  line(222, 164, 229, 164, REGION_3),

  line(103, 283, 103, 292, REGION_4),
  line(103, 292, 110, 292, REGION_4),
  line(110, 292, 110, 298, REGION_4),
  line(110, 298, 128, 298, REGION_4),
  line(128, 298, 128, 281, REGION_4),
  line(128, 281, 139, 281, REGION_4),
  line(119, 298, 119, 290, REGION_4),
  line(119, 290, 139, 290, REGION_4),
  line(139, 290, 139, 277, REGION_4),
  line(139, 277, 152, 277, REGION_4),
  line(152, 277, 152, 284, REGION_4),
  line(152, 284, 166, 284, REGION_4),
  line(166, 284, 166, 273, REGION_4),

  line(143, 277, 143, 263, REGION_4),
  line(118, 263, 118, 224, REGION_4),
  line(118, 224, 169, 224, REGION_4),
  line(169, 224, 169, 263, REGION_4),
  line(169, 263, 118, 263, REGION_4),
  line(118, 248, 169, 248, REGION_4),
  line(118, 239, 169, 239, REGION_4),
  line(118, 232, 125, 232, REGION_4),
  line(125, 232, 125, 239, REGION_4),
  line(118, 256, 125, 256, REGION_4),
  line(125, 256, 125, 248, REGION_4),
  line(161, 239, 161, 232, REGION_4),
  line(161, 232, 169, 232, REGION_4),
  line(161, 248, 161, 256, REGION_4),
  line(161, 256, 169, 256, REGION_4),

  line(143, 221, 143, 202, REGION_4),
  line(143, 202, 85, 202, REGION_4),
  line(85, 202, 85, 230, REGION_4),
  line(85, 230, 92, 230, REGION_4),
  line(92, 230, 92, 244, REGION_4),
  line(92, 244, 85, 244, REGION_4),
  line(85, 244, 85, 250, REGION_4),
  line(85, 250, 92, 250, REGION_4),
  line(92, 250, 92, 256, REGION_4),
  line(92, 256, 85, 256, REGION_4),
  line(85, 256, 85, 264, REGION_4),
  line(70, 238, 92, 238, REGION_4),
  line(88, 212, 97, 212, REGION_4),
  line(97, 212, 97, 223, REGION_4),
  line(97, 223, 85, 223, REGION_4),
  line(143, 209, 118, 209, REGION_4),
  line(118, 209, 118, 194, REGION_4),
  line(134, 202, 134, 209, REGION_4),
  line(130, 202, 130, 191, REGION_4),
  line(130, 191, 103, 191, REGION_4),
  line(103, 191, 103, 202, REGION_4),

  line(82, 212, 49, 212, REGION_4),
  line(49, 212, 49, 227, REGION_4),
  line(49, 227, 43, 227, REGION_4),
  line(43, 227, 43, 230, REGION_4),
  line(43, 230, 29, 230, REGION_4),
  line(65, 212, 65, 205, REGION_4),
  line(65, 205, 56, 205, REGION_4),
  line(56, 205, 56, 212, REGION_4),
  line(35, 220, 35, 212, REGION_4),
  line(35, 212, 19, 212, REGION_4),
  line(19, 212, 19, 223, REGION_4),
  line(19, 223, 29, 223, REGION_4),

  line(49, 220, 29, 220, REGION_4),
  line(29, 220, 29, 251, REGION_4),
  line(27, 241, 16, 241, REGION_4),
  line(16, 241, 16, 251, REGION_4),
  line(16, 251, 37, 251, REGION_4),
  line(37, 251, 37, 262, REGION_4),
  line(37, 262, 22, 262, REGION_4),
  line(22, 262, 22, 251, REGION_4),
  line(29, 262, 29, 269, REGION_4),
  line(29, 269, 50, 269, REGION_4),
  line(50, 269, 50, 293, REGION_4),
  line(50, 293, 64, 293, REGION_4),
  line(64, 293, 64, 290, REGION_4),
  line(64, 290, 77, 290, REGION_4),
  line(77, 290, 77, 298, REGION_4),
  line(77, 298, 64, 298, REGION_4),
  line(64, 298, 64, 293, REGION_4),
  line(67, 290, 67, 279, REGION_4),
  line(35, 269, 35, 287, REGION_4),
  line(35, 287, 50, 287, REGION_4),
  line(35, 278, 50, 278, REGION_4),
  line(37, 256, 50, 256, REGION_4),

  line(362, 218, 383, 218, REGION_5),
  line(383, 218, 383, 227, REGION_5),
  line(383, 227, 379, 227, REGION_5),
  line(379, 227, 379, 235, REGION_5),
  line(379, 235, 388, 235, REGION_5),
  line(388, 235, 388, 244, REGION_5),
  line(388, 244, 358, 244, REGION_5),
  line(358, 244, 358, 235, REGION_5),
  line(358, 235, 367, 235, REGION_5),
  line(367, 235, 367, 227, REGION_5),
  line(367, 227, 362, 227, REGION_5),
  line(362, 227, 362, 218, REGION_5),
  line(373, 244, 373, 262, REGION_5),
  line(373, 251, 385, 251, REGION_5),
  line(385, 251, 385, 269, REGION_5),
  line(385, 269, 394, 269, REGION_5),
  line(394, 269, 394, 254, REGION_5),
  line(394, 254, 403, 254, REGION_5),
  line(403, 254, 403, 262, REGION_5),
  line(403, 262, 362, 262, REGION_5),
  line(362, 262, 362, 274, REGION_5),
  line(362, 274, 368, 274, REGION_5),
  line(368, 274, 368, 280, REGION_5),
  line(368, 280, 377, 280, REGION_5),
  line(377, 280, 377, 305, REGION_5),
  line(377, 305, 389, 305, REGION_5),
  line(389, 305, 389, 287, REGION_5),
  line(389, 295, 403, 295, REGION_5),
  line(403, 295, 403, 287, REGION_5),
  line(403, 287, 359, 287, REGION_5),
  line(359, 287, 359, 283, REGION_5),
  line(359, 283, 352, 283, REGION_5),
  line(364, 287, 364, 296, REGION_5),
  line(364, 296, 377, 296, REGION_5),
  line(362, 268, 352, 268, REGION_5),
  line(352, 268, 352, 272, REGION_5),
  line(352, 272, 341, 272, REGION_5),
  line(341, 272, 341, 280, REGION_5),
  line(341, 280, 335, 280, REGION_5),
  line(335, 280, 335, 289, REGION_5),
  line(335, 289, 352, 289, REGION_5),
  line(352, 289, 352, 280, REGION_5),
  line(352, 280, 341, 280, REGION_5),

  line(335, 286, 311, 286, REGION_5),
  line(311, 286, 311, 292, REGION_5),
  line(311, 292, 307, 292, REGION_5),
  line(307, 292, 307, 299, REGION_5),
  line(307, 299, 316, 299, REGION_5),
  line(316, 299, 316, 310, REGION_5),
  line(316, 310, 292, 310, REGION_5),
  line(292, 310, 292, 299, REGION_5),
  line(292, 299, 296, 299, REGION_5),
  line(296, 299, 296, 295, REGION_5),
  line(296, 295, 307, 295, REGION_5),
  line(292, 304, 269, 304, REGION_5),
  line(269, 304, 269, 313, REGION_5),
  line(269, 313, 284, 313, REGION_5),
  line(284, 313, 284, 304, REGION_5),
  line(269, 310, 262, 310, REGION_5),
  line(247, 304, 262, 304, REGION_5),
  line(262, 304, 262, 313, REGION_5),
  line(262, 313, 247, 313, REGION_5),
  line(247, 313, 247, 295, REGION_5),
  line(247, 295, 233, 295, REGION_5),
  line(233, 295, 233, 298, REGION_5),
  line(233, 298, 229, 298, REGION_5),
  line(229, 298, 229, 302, REGION_5),
  line(229, 302, 206, 302, REGION_5),
  line(220, 302, 220, 277, REGION_5),
  line(220, 277, 230, 277, REGION_5),
  line(230, 277, 230, 280, REGION_5),
  line(230, 280, 235, 280, REGION_5),
  line(235, 280, 235, 284, REGION_5),
  line(235, 284, 239, 284, REGION_5),
  line(239, 284, 239, 295, REGION_5),
  line(205, 300, 205, 251, REGION_5),
  line(205, 251, 254, 251, REGION_5),
  line(254, 251, 254, 260, REGION_5),
  line(254, 260, 262, 260, REGION_5),
  line(262, 260, 262, 269, REGION_5),
  line(262, 269, 271, 269, REGION_5),
  line(271, 278, 271, 265, REGION_5),
  line(271, 265, 293, 265, REGION_5),
  line(293, 265, 293, 272, REGION_5),
  line(293, 272, 322, 272, REGION_5),
  line(314, 262, 322, 262, REGION_5),
  line(322, 262, 322, 285, REGION_5),

  line(205, 290, 194, 290, REGION_5),
  line(194, 290, 194, 283, REGION_5),
  line(194, 283, 205, 283, REGION_5),
  line(205, 275, 191, 275, REGION_5),
  line(191, 275, 191, 268, REGION_5),
  line(191, 268, 205, 268, REGION_5),
  line(205, 259, 188, 259, REGION_5),
  line(188, 259, 188, 249, REGION_5),
  line(247, 251, 247, 242, REGION_5),
  line(247, 242, 286, 242, REGION_5),
  line(286, 242, 286, 256, REGION_5),
  line(286, 256, 280, 256, REGION_5),
  line(280, 256, 280, 265, REGION_5),

  line(268, 200, 280, 200, REGION_6),
  line(311, 184, 322, 184, REGION_6),
  line(299, 190, 311, 190, REGION_6),
  line(299, 200, 311, 200, REGION_6),
  line(311, 205, 322, 205, REGION_6),
  line(268, 211, 292, 211, REGION_6),
  line(304, 212, 317, 212, REGION_6),
  line(268, 220, 326, 220, REGION_6),
  line(270, 230, 304, 230, REGION_6),
  line(317, 229, 347, 229, REGION_6),
  line(304, 236, 326, 236, REGION_6),
  line(317, 244, 326, 244, REGION_6),

  line(268, 200, 268, 220, REGION_6),
  line(280, 200, 280, 230, REGION_6),
  line(292, 211, 292, 230, REGION_6),
  line(299, 191, 299, 200, REGION_6),
  line(311, 184, 311, 205, REGION_6),
  line(322, 184, 322, 205, REGION_6),
  line(304, 212, 304, 236, REGION_6),
  line(317, 205, 317, 244, REGION_6),
  line(326, 220, 326, 244, REGION_6),
  line(340, 229, 340, 238, REGION_6),
  line(347, 221, 347, 229, REGION_6),

  line(334, 154, 347, 154, REGION_6),
  line(356, 154, 391, 154, REGION_6),
  line(388, 161, 391, 161, REGION_6),
  line(365, 163, 376, 163, REGION_6),
  line(303, 163, 323, 163, REGION_6),
  line(334, 166, 347, 166, REGION_6),
  line(356, 166, 365, 166, REGION_6),
  line(376, 166, 388, 166, REGION_6),
  line(313, 173, 337, 173, REGION_6),
  line(388, 173, 395, 173, REGION_6),
  line(337, 178, 345, 178, REGION_6),
  line(378, 178, 388, 178, REGION_6),
  line(395, 178, 407, 178, REGION_6),
  line(329, 182, 337, 182, REGION_6),
  line(388, 182, 395, 182, REGION_6),
  line(337, 190, 349, 190, REGION_6),
  line(359, 190, 368, 190, REGION_6),
  line(377, 190, 391, 190, REGION_6),
  line(334, 194, 337, 194, REGION_6),
  line(349, 193, 359, 193, REGION_6),
  line(334, 202, 368, 202, REGION_6),
  line(377, 202, 391, 202, REGION_6),

  line(313, 163, 313, 173, REGION_6),
  line(323, 163, 323, 173, REGION_6),

  line(329, 173, 329, 182, REGION_6),
  line(334, 154, 334, 166, REGION_6),

  line(334, 194, 334, 202, REGION_6),
  line(337, 166, 337, 194, REGION_6),
  line(347, 154, 347, 166, REGION_6),
  line(349, 190, 349, 202, REGION_6),
  line(356, 154, 356, 166, REGION_6),
  line(359, 190, 359, 202, REGION_6),
  line(365, 154, 365, 166, REGION_6),

  line(368, 190, 368, 202, REGION_6),
  line(376, 154, 376, 166, REGION_6),
  line(377, 190, 377, 202, REGION_6),
  line(388, 161, 388, 190, REGION_6),
  line(395, 173, 395, 182, REGION_6),
  line(407, 165, 407, 178, REGION_6),
  line(391, 154, 391, 161, REGION_6),
  line(391, 190, 391, 202, REGION_6),
  line(362, 166, 362, 190, REGION_6),

  line(125, 142, 169, 142, REGION_6),
  line(169, 142, 169, 124, REGION_6),
  line(169, 124, 176, 124, REGION_6),
  line(188, 118, 188, 106, REGION_6),
  line(188, 106, 203, 106, REGION_6),
  line(203, 106, 203, 119, REGION_6),
  line(203, 119, 215, 119, REGION_6),
  line(215, 119, 215, 124, REGION_6),
  line(215, 124, 223, 124, REGION_6),
  line(223, 124, 223, 119, REGION_6),
  line(223, 119, 265, 119, REGION_6),
  line(265, 119, 265, 128, REGION_6),
  line(265, 128, 271, 128, REGION_6),
  line(271, 128, 271, 137, REGION_6),
  line(271, 137, 275, 137, REGION_6),
  line(275, 137, 275, 152, REGION_6),
  line(275, 152, 299, 152, REGION_6),
  line(299, 152, 299, 187, REGION_6),
  line(284, 152, 284, 163, REGION_6),
  line(284, 163, 298, 163, REGION_6),
  line(169, 131, 140, 131, REGION_6),
  line(140, 131, 140, 142, REGION_6),
  line(158, 131, 158, 140, REGION_6),
  line(188, 110, 175, 110, REGION_6),
  line(175, 110, 175, 100, REGION_6),
  line(175, 100, 182, 100, REGION_6),
  line(182, 100, 182, 92, REGION_6),
  line(182, 92, 209, 92, REGION_6),
  line(209, 92, 209, 100, REGION_6),
  line(209, 100, 217, 100, REGION_6),
  line(217, 100, 217, 110, REGION_6),
  line(217, 110, 203, 110, REGION_6),
  line(188, 119, 176, 119, REGION_6),
  line(176, 119, 176, 124, REGION_6),
  line(250, 119, 250, 128, REGION_6),
  line(250, 128, 256, 128, REGION_6),
  line(256, 128, 256, 137, REGION_6),
  line(256, 137, 262, 137, REGION_6),
  line(262, 137, 262, 146, REGION_6),
  line(262, 146, 275, 146, REGION_6),

  line(125, 118, 125, 166, REGION_6),
  line(125, 166, 149, 166, REGION_6),
  line(149, 166, 149, 181, REGION_6),
  line(149, 181, 154, 181, REGION_6),
  line(154, 181, 154, 190, REGION_6),
  line(154, 190, 160, 190, REGION_6),
  line(160, 190, 160, 199, REGION_6),
  line(160, 199, 202, 199, REGION_6),
  line(202, 199, 202, 194, REGION_6),
  line(202, 194, 209, 194, REGION_6),
  line(209, 194, 209, 199, REGION_6),
  line(209, 199, 221, 199, REGION_6),
  line(221, 199, 221, 212, REGION_6),
  line(221, 212, 236, 212, REGION_6),
  line(236, 212, 236, 199, REGION_6),
  line(236, 199, 248, 199, REGION_6),
  line(248, 199, 248, 194, REGION_6),
  line(248, 194, 256, 194, REGION_6),
  line(256, 194, 256, 176, REGION_6),
  line(256, 176, 299, 176, REGION_6),
  line(284, 176, 284, 187, REGION_6),
  line(284, 187, 256, 187, REGION_6),
  line(266, 179, 266, 187, REGION_6),
  line(125, 155, 140, 155, REGION_6),
  line(140, 155, 140, 166, REGION_6),
  line(149, 172, 163, 172, REGION_6),
  line(163, 172, 163, 181, REGION_6),
  line(163, 181, 169, 181, REGION_6),
  line(169, 181, 169, 190, REGION_6),
  line(169, 190, 175, 190, REGION_6),
  line(175, 190, 175, 199, REGION_6),
  line(221, 208, 208, 208, REGION_6),
  line(208, 208, 208, 218, REGION_6),
  line(208, 218, 215, 218, REGION_6),
  line(215, 218, 215, 226, REGION_6),
  line(215, 226, 242, 226, REGION_6),
  line(242, 226, 242, 218, REGION_6),
  line(242, 218, 250, 218, REGION_6),
  line(250, 218, 250, 208, REGION_6),
  line(250, 208, 236, 208, REGION_6),

  line(103, 113, 113, 113, REGION_6),
  line(98, 74, 107, 74, REGION_6),
  line(98, 82, 121, 82, REGION_6),
  line(77, 89, 107, 89, REGION_6),
  line(121, 88, 155, 88, REGION_6),
  line(98, 98, 157, 98, REGION_6),
  line(107, 106, 121, 106, REGION_6),
  line(133, 107, 157, 107, REGION_6),
  line(145, 118, 157, 118, REGION_6),
  line(113, 118, 125, 118, REGION_6),
  line(113, 128, 124, 128, REGION_6),
  line(103, 134, 113, 134, REGION_6),
  line(77, 89, 77, 98, REGION_6),
  line(98, 74, 98, 98, REGION_6),
  line(107, 74, 107, 113, REGION_6),
  line(121, 82, 121, 106, REGION_6),
  line(133, 88, 133, 107, REGION_6),
  line(145, 88, 145, 118, REGION_6),
  line(157, 98, 157, 118, REGION_6),
  line(103, 113, 103, 134, REGION_6),
  line(113, 113, 113, 134, REGION_6),
  line(85, 89, 85, 78, REGION_6),

  line(34, 116, 47, 116, REGION_6),
  line(56, 116, 91, 116, REGION_6),
  line(88, 124, 91, 124, REGION_6),
  line(65, 125, 76, 125, REGION_6),
  line(34, 128, 47, 128, REGION_6),
  line(56, 128, 65, 128, REGION_6),
  line(76, 128, 88, 128, REGION_6),
  line(29, 136, 37, 136, REGION_6),
  line(88, 136, 95, 136, REGION_6),
  line(17, 140, 29, 140, REGION_6),
  line(37, 140, 47, 140, REGION_6),
  line(81, 140, 88, 140, REGION_6),
  line(29, 145, 37, 145, REGION_6),
  line(88, 145, 112, 145, REGION_6),
  line(37, 152, 49, 152, REGION_6),
  line(59, 152, 68, 152, REGION_6),
  line(77, 152, 91, 152, REGION_6),
  line(49, 155, 59, 155, REGION_6),
  line(34, 157, 37, 157, REGION_6),
  line(101, 155, 125, 155, REGION_6),
  line(34, 164, 68, 164, REGION_6),
  line(77, 164, 91, 164, REGION_6),
  line(17, 140, 17, 155, REGION_6),

  line(29, 136, 29, 145, REGION_6),
  line(34, 116, 34, 128, REGION_6),
  line(34, 157, 34, 164, REGION_6),
  line(37, 128, 37, 157, REGION_6),
  line(47, 116, 47, 128, REGION_6),
  line(49, 152, 49, 164, REGION_6),
  line(56, 116, 56, 128, REGION_6),
  line(62, 128, 62, 152, REGION_6),
  line(65, 116, 65, 128, REGION_6),
  line(68, 152, 68, 164, REGION_6),
  line(76, 116, 76, 128, REGION_6),
  line(77, 152, 77, 164, REGION_6),
  line(88, 124, 88, 152, REGION_6),
  line(91, 116, 91, 124, REGION_6),
  line(91, 152, 91, 164, REGION_6),
  line(95, 136, 95, 145, REGION_6),
  line(101, 145, 101, 155, REGION_6),
  line(112, 145, 112, 155, REGION_6),
  line(59, 152, 59, 164, REGION_6),
]

export const Regions = [
  {
    points: '69,103 69,112 5,112 5,1 247,1 247,81 132,81 120,70 73,70',
    fillColour: '#FFFFFF',
    digLocations: [
      'Snowpoint City & Adjoining Routes', 
      'Full Moon Island'
    ]
  },
  {
    points: '248,1 426,1 426,138 315,138 295,138 248,93',
    fillColour: '#00FF00',
    digLocations: ['Battle Zone']
  },
  {
    points: '247,135 247,93 295,139 426,139 426,207 357,207 357,225 347,235 347,250 300,250 300,238 200,238 200,208 150,208 150,186 140,186 140,180 5,180 5,113 70,113 70,103 74,71 120,71 132,82 215,82 247,82 247,135 238,128 175,128 175,180 185,192 247,192',
    fillColour: '#E75480',
    digLocations: [
      'Everywhere else'
    ]
  },
  {
    points: '176,129 238,129 246,135 246,191 186,191 176,179',
    fillColour: '#0000FF',
    digLocations: ['Celestic Town']
  },
  {
    points: '149,187 149,209 199,209 199,238 176,238 176,317 5,317 5,181 139,181 139,187',
    fillColour: '#FFA500',
    digLocations: [
      'Ramanas Park & Adjoining Routes','Twinleaf Town & Adjoining Routes','Canalave City & Adjoining Routes'
    ]
  },
  {
    points: '299,239 299,251 348,251 348,235 358,225 358,208 426,208 426,250 426,317 177,317 177,239',
    fillColour: '#FF0000',
    digLocations: [
      'Sunyshore City',
      'South east of Route 213'
    ]
  },
]