<template>
  <div class="hello">
    <h1>BDSP Grand Underground Map</h1>
    <o-field label="Game">
      <Multiselect
        v-model="game"
        :options="['Brilliant Diamond', 'Shining Pearl']"
        :canClear="false"
      />
    </o-field>

    <svg width="430" height="320" style="background-color: black">
      <line
        v-for="(line, idx) in lines"
        :key="`line-${idx}`"
        :x1="line.x1"
        :x2="line.x2"
        :y1="line.y1"
        :y2="line.y2"
        :stroke="line.stroke"
        stroke-width="2"
        stroke-linecap="round"
      />
      <image
        v-for="(location, idx) in locations"
        :key="`${location.id}-${idx}`"
        :href="icons[location.cavern]"
        :x="location.x"
        :y="location.y"
        :width="location.size"
        :height="location.size"
        :onclick="select(location)"
        :style="getStyle(location)"
      />
    </svg>

    <div v-if="selected" style="max-width: 400px; margin: auto;">
      What you'll find in the <b>{{ locationMap[selected.cavern] }}</b> in <b>{{ game }}</b>
      <o-table
        :data="cavernPokemonData"
        :bordered="true"
        
      >
        <o-table-column field="requirement" label="Requirement" width="40" v-slot="props">
          {{ props.row.requirement }}
        </o-table-column>
        <o-table-column field="pokemon" label="Pokemon" width="40" v-slot="props">
          {{ props.row.pokemon.join(', ') }}
        </o-table-column>
      </o-table>
    </div>
    <div class="tagline">
      Any issues or bugs, please let me know on <a href="http://twitter.com/Dezzles" target="_blank">Twitter @Dezzles</a>
    </div>
  </div>
</template>

<script>
const pokemon = require('./pokemon.json')
const caverns = require('./caverns.json')
import {Icons} from './icons';
import '@vueform/multiselect/themes/default.css' // This is required, we use the same css as selectize.js
import Multiselect from '@vueform/multiselect'
import { Paths, Caverns } from './map'
import { LocationMap } from './LocationMap'
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    Multiselect
  },
  data() {
    return {
      test: '',
      pokemon,
      game: 'Brilliant Diamond',
      selectedPokemon: 'Charmander',
      selected: null,
      cavernPokemon: caverns,
      icons: Icons,
      lines: Paths,
      locations: Caverns,
      locationMap: LocationMap
    };
  },
  methods: {
    select: function (val) {
      return () => {
        this.selected = val
      };
    },
    getStyle(location) {
      if (this.selected == null || (this.selected.cavern !== location.cavern)) {
        return {
          filter: 'brightness(0.2)'
        }
      }
      return {
        filter: 'brightness(1)',
        outline: '1px solid yellow'
      };
    },
  },
  computed: {
    pokemonOptions: function () {
      return Object.keys(this.pokemon).sort()
        //.filter(v => this.pokemon[v][this.game].length)
    },
    currentPokemon: function () {
      return this.pokemon[this.selectedPokemon]
    },
    currentGame: function () {
      return this.currentPokemon[this.game]
    },
    cavernPokemonData: function () {
      if (!this.selected) {
        return []
      }
      const data = caverns[this.locationMap[this.selected.cavern]][this.game]

      return Object.keys(data).reduce((prev, curr) => {
        prev.push({
          requirement: curr,
          pokemon: data[curr]
        })
        return prev
      }, [])
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.table {
  background-color: transparent !important;
}

.b-table.table-wrapper.has-mobile-cards tr:not([class*=is-]) {
    background-color: transparent !important;
}

.tagline {
  margin-top: 20px;
  font-size: .8em;
}
.multiselect {
  width: 300px !important;
}
.multiselect-single-label {
  color: gray !important;
}

h1 { 
  color: #bac6d3;
}
</style>
