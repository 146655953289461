<template>
  <o-tabs v-model="activeTab">
    <o-tab-item :value="0" label="Pokemon">
      <Pokemon />
    </o-tab-item>
    <o-tab-item :value="1" label="Digging">
      <Digging />
    </o-tab-item>
    <o-tab-item :value="2" label="Caverns">
      <Caverns />
    </o-tab-item>
    <o-tab-item :value="3" label="NPCs">
      <NPCs />
    </o-tab-item>
  </o-tabs>
</template>

<script>
import Pokemon from './components/Pokemon.vue'
import Digging from './components/Digging.vue'
import Caverns from './components/Caverns.vue'
import NPCs from './components/NPCs.vue'
export default {
  name: 'App',
  components: {
    Digging,
    Pokemon,
    Caverns,
    NPCs
  },
  data: function () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

html {
  background-color: black !important;
}
</style>
