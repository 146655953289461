export const Icons = {
  bigbluffcavern: require("@/assets/bigbluffcavern.png"),
  bogsunkcavern: require("@/assets/bogsunkcavern.png"),
  dazzlingcave: require("@/assets/dazzlingcave.png"),
  fountainspringcave: require("@/assets/fountainspringcave.png"),
  glacialcavern: require("@/assets/glacialcavern.png"),
  grasslandcave: require("@/assets/grasslandcave.png"),
  icycave: require("@/assets/icycave.png"),
  logo: require("@/assets/logo.png"),
  riverbankcave: require("@/assets/riverbankcave.png"),
  rockycave: require("@/assets/rockycave.png"),
  sandsearcave: require("@/assets/sandsearcave.png"),
  spaciouscave: require("@/assets/spaciouscave.png"),
  stargleamcavern: require("@/assets/stargleamcavern.png"),
  stillwatercavern: require("@/assets/still-watercavern.png"),
  sunlitcavern: require("@/assets/sunlitcavern.png"),
  typhlocavern: require("@/assets/sandsearcave.png"),
  swampycave: require("@/assets/swampycave.png"),
  volcaniccave: require("@/assets/volcaniccave.png"),
  whiteoutcave: require("@/assets/whiteoutcave.png"),
}
