<template>
  <div class="hello">
    <h1>BDSP Grand Underground NPC Map</h1>
    <o-field label="NPC Type">
      <Multiselect
        v-model="selected"
        :options="Object.keys(npcs)"
        :canClear="false"
      />
    </o-field>

    <svg width="430" height="320" style="background-color: black">

      <line
        v-for="(line, idx) in lines"
        :key="`line-${idx}`"
        :x1="line.x1"
        :x2="line.x2"
        :y1="line.y1"
        :y2="line.y2"
        :stroke="line.stroke"
        stroke-width="2"
        stroke-linecap="round"
      />
      <image
        v-for="(location, idx) in locations"
        :key="`${location.id}-${idx}`"
        :href="icons[location.cavern]"
        :x="location.x"
        :y="location.y"
        :width="location.size"
        :height="location.size"
      />
      <rect
        x="0"
        y="0"
        width="430"
        height="320"
        style="fill: #000000AA;"
      />
      <circle
        v-for="(npc, idx) in npcs[selected]"
        :key="`npc-${idx}`"
        :cx="npc.x"
        :cy="npc.y"
        r="3"
        style="fill:white; stroke: blue;"
        class="triangle-pulse"
      />
    </svg>

    <div class="tagline">
      Any issues or bugs, please let me know on <a href="http://twitter.com/Dezzles" target="_blank">Twitter @Dezzles</a>
    </div>
  </div>
</template>

<script>
import {Icons} from './icons';
import '@vueform/multiselect/themes/default.css' // This is required, we use the same css as selectize.js
import Multiselect from '@vueform/multiselect'
import { Paths, Caverns } from './map'
import { LocationMap } from './LocationMap'
import {NPCs} from './npcData'
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    Multiselect
  },
  data() {
    return {
      selected: Object.keys(NPCs)[0],
      icons: Icons,
      lines: Paths,
      locations: Caverns,
      locationMap: LocationMap,
      npcs: NPCs
    };
  },
  methods: {
    select: function (val) {
      return () => {
        this.selected = val
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.table {
  background-color: transparent !important;
}

.b-table.table-wrapper.has-mobile-cards tr:not([class*=is-]) {
    background-color: transparent !important;
}

.tagline {
  margin-top: 20px;
  font-size: .8em;
}
.multiselect {
  width: 300px !important;
}
.multiselect-single-label {
  color: gray !important;
}

h1 { 
  color: #bac6d3;
}

@keyframes pulse {
	0% {
    -webkit-filter: drop-shadow( 0 0 3px rgba(0, 0, 255, .4));
    filter: drop-shadow( 0 0 3px rgba(0, 0, 255, .0));
    stroke-width: 1px;
	}

	50% {
    -webkit-filter: drop-shadow( 0 0 3px rgba(0, 0, 255, 1));
    filter: drop-shadow( 0 0 3px rgba(0, 0, 255, 1));
    stroke-width: 0px;
	}

	100% {
    -webkit-filter: drop-shadow( 0 0 3px rgba(0, 0, 255, .4));
    filter: drop-shadow( 0 0 3px rgba(0, 0, 255, .0));
    stroke-width: 1px;
	}
}

.triangle-pulse {
 -webkit-filter: drop-shadow( 0px 0px 0 rgba(0, 0, 255, .7));
  filter: drop-shadow( 0px 0px 0 rgba(0, 0, 255, .7));
	animation: pulse 2s infinite;
  box-shadow: #bac6d3;
}
</style>
